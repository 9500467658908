import React from 'react';
import cartimg from "../images/online.jpg";
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import ProgressiveImage from "react-progressive-graceful-image";

const About = () => {
	return(
    <>
      <Header/>
      <div className="container-fluid">

      <div className="py-3"></div>
      <div className="row">
      <div className="col-md-6 mb-3">
      <div className="card soko-round shadow-lg h-100">
      <div className="card-body">
      {/*<img loading="lazy" className="soko-round img-fluid" src={cartimg} alt="About Img" />*/}
            <ProgressiveImage src={cartimg} placeholder={cartimg}>
      {(src, loading) => (
        <img
          className={`image${loading ? " loading" : " loaded"}`}
          src={src}
          alt="about sokomoko"
        />
      )}
     </ProgressiveImage>
      </div>
      </div>
      </div>
      <div className="col-md-6 mb-3">
      <div className="card soko-round shadow-lg h-100">
      <div className="card-body">
      <p> SokoMoko, Kenya's no. 1 online retailer was established in May 2023 with the aim and vision to become the one-stop shop for retail in Kenya with implementation of best practices both online and offline.
      SokoMoko is the largest online retail store in Kenya.
      At inception we did an average delivery time of a week, today we do, on average, delivery in 1-5 days.
      Initially starting with 3 employees, SokoMoko presently has a staff strength of 1000 young and entrepreneurial Kenyans including our 150 man strong customer service team available 7 days a week.
      Country-wide Delivery in Kenya.
      </p>
      </div>
      </div>
      </div>
      </div>
      </div>

      <Footer/>
   </>

	)
}

export default About