import React from 'react';
import { faHome } from "@fortawesome/free-solid-svg-icons";

import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faBookmark } from "@fortawesome/free-regular-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {Link} from 'react-router-dom';



const Footer = () => {


		return(

	   <div className="footer-nav-area shadow-lg" id="footerNav">
         <div className="container-fluid h-100">
            <div className="soko-footer-nav h-100">
               <ul className="h-100 d-flex align-items-center justify-content-between ps-0">
                  <li>

                     <Link className="text-site text-center" to={'/'}><FontAwesomeIcon icon={faHome} /><span>Home</span></Link>
                   
                  </li>
              
               
                  {

                  localStorage.getItem('user-info') ?
                  <>
                       <li>
                     <Link className="text-site" to={'/bookmarks'}><FontAwesomeIcon icon={faBookmark} /><span>Saved</span></Link>
                  </li>
                    <li>
                     <Link className="text-site text-primary" to={'/sell'}><FontAwesomeIcon className="fs-4" icon={faPlusSquare} /><span>Sell</span></Link>
                  </li>
                  <li> 
                     <Link className="text-site" to={'/messages'}><FontAwesomeIcon icon={faMessage} /><span>Messages</span></Link>
                  </li>
                   <li>
                     <Link className="text-site" to={`/myprofile`}><FontAwesomeIcon icon={faUserCircle} /><span>Profile</span></Link>
                  </li>
                    
                  </>
                  :

                  <>
                   <li>
                     <Link className="text-site" to={'/login'}><FontAwesomeIcon icon={faBookmark} /><span>Saved</span></Link>
                  </li>
                      <li>
                     <Link className="text-site text-primary" to={'/login'}><FontAwesomeIcon className="fs-4" icon={faPlusSquare} /><span>Sell</span></Link>
                  </li>
                  <li>
                     <Link className="text-site" to={'/register'}><FontAwesomeIcon icon={faUserPlus} /><span>Sign Up</span></Link>
                  </li>

                    <li>
                     <Link className="text-site" to={'/login'}><FontAwesomeIcon icon={faSignIn} /><span>Sign In</span></Link>
                  </li>
                  </>

                  }
              
                 
                
               </ul>
            </div>
         </div>
      </div>
			)
}

export default Footer;
