import React from 'react';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import Header from './layouts/Header';
import Footer from './layouts/Footer';

const Products = () => {

const [products, setProducts] = useState([]);
const [isLoading, setIsLoading] = useState(true);
const fetchData = () =>{
return fetch("https://mysoko.codekali.com/api/getproducts")
.then((response) => response.json())
.then((data) => setProducts(data['products']))
 .finally(() => {
  setIsLoading(false);
 });
}

// console.log(products);
 
useEffect(() => {
setIsLoading(true);
fetchData();
// setIsLoading(false);
},[])
 

return(
 <>
      <Header/>
      <div className="container-fluid">
         <div className="py-3"></div>
         <div className="user-container">
            <div className="row">
               { !isLoading ? (products&&products.length>0 && products.map(productsObj => (
               <div key={productsObj.id} className="col-md-3 mb-3">
                  <div className="card soko-round shadow-lg h-100">
                     <div className="card-body">
                     <Link to={`/product/${productsObj.slug}`}>
                        <img loading="lazy" className="img-fluid product-img" src={productsObj.image ? `https://mysoko.codekali.com/product/image/${productsObj.image}` : `https://mysoko.codekali.com/app/images/avatar.png`} 
                        alt="{productsObj.name}" />
                        </Link>
                        <div className="mb-2">
                           <small><span className="badge badge-warning bg-warning soko-round">{productsObj.category.name}</span></small>
                        </div>
                        <Link to={`/product/${productsObj.slug}`}><h6>{productsObj.name}</h6></Link>
                        <h5>Ksh.{productsObj.price}</h5>
                     
                          <Link className="btn btn-outline-primary soko-round w-100" to={`/product/${productsObj.slug}`}>
                        <FontAwesomeIcon className="me-1" icon={faCartPlus} /> Buy</Link>
               

                       

                     </div>
                   
                  </div>
               </div>
               ))) :  Array(50)
               .fill()
               .map((item, index) => (
               <div className="col-md-3 mb-3" key={index}>
                  <div className="card soko-round shadow-lg">
                     <div className="card-body">
                     <div style={{ height: "200px", marginBottom: ".5rem" }}>
                           <Skeleton height={200} width={`100%`} />
                        </div>
                         <h6 className="card-title">
                           <Skeleton height={25} width={`80%`} />
                        </h6>
                        <p className="card-channel mb-0">
                           <Skeleton width={`100%`} />
                        </p>
                        <p className="card-channel mb-3">
                           <Skeleton width={`100%`} />
                        </p>
                         <p className="card-channel mb-3">
                           <Skeleton width={`100%`} />
                        </p>
                         

                        <div className="row">
                           <div className="col-md-6 mb-3">
                              <div className="card-metrics">
                           <Skeleton height={30} width={`100%`} />
                        </div>
                           </div>
                            <div className="col-md-6 mb-3">
                              <div className="card-metrics">
                           <Skeleton height={30} width={`100%`} />
                        </div>
                           </div>
                             <div className="col-md-6">
                              <div className="card-metrics">
                           <Skeleton height={30} width={`100%`} />
                        </div>
                           </div>
                        </div>

                     </div>

                  
                  </div>
               </div>
               )) 
               }
            </div>
         </div>
      </div>
      <Footer/>
</>
)
}

export default Products
