import React, { useState } from "react";

import Header from './layouts/Header';
import Footer from './layouts/Footer';

import Select from 'react-select';

export default function MySelect() {

const aquaticCreatures = [
{ label: 'Shark', value: 'Shark' },
{ label: 'Dolphin', value: 'Dolphin' },
{ label: 'Whale', value: 'Whale' },
{ label: 'Octopus', value: 'Octopus' },
{ label: 'Crab', value: 'Crab' },
{ label: 'Lobster', value: 'Lobster' },
];
 

   const colourOptions = [
        { value: 'chocolate', label: 'Chocolate' },
        { value: 'strawberry', label: 'Strawberry' },
        { value: 'vanilla', label: 'Vanilla' }
      ];


    const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRtl, setIsRtl] = useState(false);

  return (
   <>
      <Header/>
      <div className="container">
         <div className="py-3"></div>
         <div className="card">
            <div className="card-body">
               <h4 className="card-title">Select Elements</h4>
               <p>Select elements with props</p> 
<div className="mb-5">
                <Select options={aquaticCreatures}/>
   
</div>

<div className="mb-5">
                      <Select
        className="basic-single"
        classNamePrefix="select"
        defaultValue={colourOptions[0]}
        isDisabled={isDisabled}
        isLoading={isLoading}
        isClearable={isClearable}
        isRtl={isRtl}
        isSearchable={isSearchable}
        name="color"
        options={colourOptions}
      />
</div>

            </div>
         </div>
      </div>
      <Footer/>
   </>
  )
}