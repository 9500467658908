import React from 'react';
import Header from './layouts/Header';
import Footer from './layouts/Footer';

 const Contact = () => {

	return(
		 <>
      <Header/>
		<div className="card soko-round shadow-lg mb-3">
			<div className="card-body">
				<h3>Hello Contact Component Here</h3>
			</div>
		</div>
      <Footer/>
		</>
	)
}

export default Contact 