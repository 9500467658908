import React, { useEffect } from "react";
import Header from './layouts/Header';
import Footer from './layouts/Footer';
import { useNavigate } from "react-router-dom";


const Profile = () => {

   let user = JSON.parse(localStorage.getItem("user-info"));

 //console.log(user.user.slug);
  const navigate = useNavigate();

    useEffect(() => {
     if (localStorage.getItem("user-info")) {
      navigate("/shop/"+user.user.slug, { replace: true });
    }

  });


	return(
    <>
      <Header/>
      <div className="container-fluid">
         <div className="py-3"></div>
      
      <div className="card soko-rounded shadow-sm h-100">
        <div className="card-body">

        <h4 className="soko-bold mb-3">My Profile</h4>

          {localStorage.getItem("user-info") ? (
              <>
                <ul className="p-0">
                <li className="mb-3"><span className="soko-bold">Name:</span> {user && user.user.name ? user.user.name : "Soko User"}</li>
                <li className="mb-3"><span className="soko-bold">Phone:</span> {user && user.user.phone ? user.user.phone : "User Phone"}</li>
                <li className="mb-3"><span className="soko-bold">Email:</span> {user && user.user.email ? user.user.email : "User Email"}</li>
                <li className="mb-3"><span className="soko-bold">Join Date:</span> {user && user.user.created_at ? user.user.created_at : "Join Date"}</li>
                </ul>
              </>
            ) : (
              <></>
            )}

        </div>
      </div>

      </div>

      <Footer/>
   </>

	)
}

export default Profile