import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faStore } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

export default function Duka() {
  const { slug } = useParams();

  const [name, setName] = useState("");
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchVendor = async () => {
      await axios
        .get(`https://mysoko.codekali.com/api/vendors/${slug}`)
        .then(({ data }) => {
          const { name } = data.user;
          setName(name);
        })
        .catch(({ response: { data } }) => {
          Swal.fire({
            text: data.message,
            icon: "error",
          });
        });
    };

    const fetchData = () => {
      return (
        fetch(`https://mysoko.codekali.com/api/vendors/${slug}`)
          // return fetch("http://127.0.0.1:8000/api/getproducts")

          .then((response) => response.json())
          .then((data) => 

             setProducts(data["user"]["products"].reverse())

            // console.log(data["user"]["products"])

            )
          .finally(() => {
            setIsLoading(false);
          })
      );
    };
    fetchVendor();
    fetchData();
  }, [slug]);


  // return;

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }
  const productsCount = products.length;


  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="py-3"></div>
        <div className="card soko-round">
          <div className="card-body">
            <h5 style={{ marginBottom: "1.5rem" }}>
              <FontAwesomeIcon icon={faStore} />{" "}
              {productsCount < 1
                ? "Loading..."
                : name + "(" + productsCount + " Products)"}{" "}
            </h5>
            <hr />
            <div className="py-1"></div>
            <div className="product-container">
              <div className="row">
                {!isLoading
                  ? products &&
                    products.length > 0 &&
                    products.map((productsObj) => (
                      <div key={productsObj.id} className="col-md-3 mb-3">
                        <div className="card soko-round shadow-lg h-100">
                          <div className="card-body">
                            <img
                              loading="lazy"
                              className="img-fluid product-img w-100"
                              src={
                                productsObj.image
                                  ? `https://mysoko.codekali.com/product/image/${productsObj.image}`
                                  : `https://mysoko.codekali.com/images/avatar.png`
                              }
                              alt="{productsObj.name}"
                            />
                            <Link to={`/product/${productsObj.slug}`}>
                              <h6 className="soko-bold">
                                {capFirst(productsObj.name)}
                              </h6>
                            </Link>
                            <p className="soko-bold">
                              KES. {productsObj.price}
                            </p>
                            <div className="row">
                              <div className="col-md-6 mb-3">
                                <Link
                                  to={""}
                                  className="btn btn-primary w-100 soko-round soko-mobile-font"
                                >
                                  <FontAwesomeIcon icon={faCartPlus} />
                                  Buy
                                </Link>
                              </div>

                              <div className="col-md-6">
                                <Link
                                  className="btn btn-outline-warning w-100 soko-round soko-mobile-font"
                                  to={`/product/${productsObj.slug}`}
                                >
                                  <FontAwesomeIcon icon={faEye} />
                                  View
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  : Array(50)
                      .fill()
                      .map((item, index) => (
                        <div className="col-md-3 mb-3" key={index}>
                          <div className="card soko-product-card soko-round shadow-lg">
                            <div className="card-body">
                              <div
                                style={{
                                  height: "200px",
                                  marginBottom: ".5rem",
                                }}
                              >
                                <Skeleton height={200} width={`100%`} />
                              </div>
                              <h4 className="card-title">
                                <Skeleton height={25} width={`80%`} />
                              </h4>
                              <p className="card-channel mb-0">
                                <Skeleton width={`60%`} />
                              </p>
                              <p className="card-channel">
                                <Skeleton width={`60%`} />
                              </p>
                              <div className="card-metrics">
                                <Skeleton width={`90%`} />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
