import React from "react";
import { faListAlt } from "@fortawesome/free-regular-svg-icons";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { faDiamond } from "@fortawesome/free-solid-svg-icons";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faStoreAlt } from "@fortawesome/free-solid-svg-icons";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { faUserCircle } from "@fortawesome/free-regular-svg-icons";
import { faBell } from "@fortawesome/free-regular-svg-icons";
import { faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import "react-loading-skeleton/dist/skeleton.css";
import SiteLogo from "../../images/sokomoko.png";
import Dropdown from "react-bootstrap/Dropdown";

const Header = () => {
  const navigate = useNavigate();

  let user = JSON.parse(localStorage.getItem("user-info"));

  // console.log(user['user']['name'])

  function logOut() {
    localStorage.clear();
    navigate("/login");
  }
 
 
  return (
    <>
      <header className="fixed-top px-0 shadow-sm">
        <div className="text-bg-info border-bottom">
          <div className="container-fluid">
            <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
              <Link
                to={'/'}
                className="d-flex align-items-center my-lg-0 me-auto text-white text-decoration-none"
              >
                <img src={SiteLogo} alt="Soko Header Logo" className="soko-header-logo" />
              </Link>

              <ul className="nav soko-app-header col-lg-auto my-2 justify-content-center my-md-0 text-small">
   <li>       
     <Link
                to={'/'}
                className="nav-link d-none"
              >
                <img src={SiteLogo} alt="Soko Nav Logo" className="soko-nav-logo" />
              </Link>
                </li>

   <li>       
                 
 
                </li>
                
                    {

                localStorage.getItem('user-info') ?
                <>
   <li>
                  <Link to={'/'} className="nav-link mobile-hide text-white">
                    <FontAwesomeIcon icon={faBookmark} />
                  </Link>
                </li>
  <li>
                  <Link to={'/'} className="nav-link mobile-hide text-white">
                    <FontAwesomeIcon icon={faMessage} />
                  </Link>
                </li>

                   <li>
                  <Link to={'/'} className="nav-link mobile-hide text-white">
                    <FontAwesomeIcon icon={faBell} />
                  </Link>
                </li>
                           
                <li>

          <Link className="nav-link mobile-hide text-white" to={'/shops'}><FontAwesomeIcon icon={faStoreAlt} /></Link>
                </li>
             
                <li>
          <Link className="nav-link text-white" to={'/dashboard'}><FontAwesomeIcon icon={faListAlt} /></Link>
                </li>
                <li className="me-3">
                  <Dropdown className="text-end">
                    <Dropdown.Toggle
                      variant="transparent"
                      className="text-white"
                      id="dropdown-basic"
                    >
                      <FontAwesomeIcon
                        className="nav-item-font"
                        icon={faUserCircle}
                      />
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item className="std-font text-dark mb-2">
                      <Link to={'/myprofile'}>
                        <FontAwesomeIcon icon={faStoreAlt} /> My Shop
                        </Link>
                      </Dropdown.Item>
                      <Dropdown.Item className="std-font text-dark mb-2">
                      <Link to={'/'}>
                      <FontAwesomeIcon icon={faCog} /> Settings
                        </Link>

                      </Dropdown.Item>

                      {localStorage.getItem("user-info") ? (
                      <>

                      <Dropdown.Item className="std-font mb-2" title={user && user.user.name ? user.user.name : "Soko User"}

                      onClick={logOut}><FontAwesomeIcon icon={faSignOut} /> Sign out</Dropdown.Item>

                      </>
                      ) : (
                      <></>
                      )}


                    </Dropdown.Menu>
                  </Dropdown>
                </li>
              

     <li className="nav-sell-btn">

   <Link to={'/sell'} className="btn sell-btn text-white w-100">
                    SELL
                  </Link>
               
                </li>

                </>
                :

                  <>
                <li>
          <Link className="nav-link soko-nav-text text-white" to={'/login'}> Sign In</Link>
                </li>
                <li>
          <Link className="nav-link soko-nav-text text-white" to={'/register'}> Registration</Link>
                </li>

     <li className="nav-sell-btn">
                  <Link to={'/login'} className="btn sell-btn text-white w-100">
                    SELL
               </Link>
                </li>

                </>

               }

             
              </ul>
            </div>
          </div>
        </div>
      </header>
 
    </>
  );
};

export default Header;
