import React from 'react';
import { useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import Header from './layouts/Header';
import Footer from './layouts/Footer';


 function SearchProduct() {
	
	const [searchproducts, setData] = useState([])
	
	 async function search(key){

		console.warn(key)

		let result = await fetch("https://mysoko.codekali.com/api/search/"+key)

		result = await result.json();

		setData(result);

		  console.log(searchproducts)

	}

	function capFirst(str) {

   let dots

   if(str.length > 50){
      dots = '...';
   }else{
      dots = '';

   }

     return (str[0].toUpperCase() + (str.slice(1)).toLowerCase()).slice(0, 50)+dots;
     
 }

	return(
	<>
    <Header/>
	<div className="container-fluid">
		<div className="py-3"></div>
			<div className="card soko-round shadow-lg mb-3">
				<div className="card-body">
				<input onChange={(e)=>search(e.target.value)} className="w-100 form-control soko-round" type="text" placeholder="Search SokoMoko"/>
				<div className="row">
					<div className="py-3"></div>
				
					{
					searchproducts.map((productsObj)=>
					<div key={productsObj.id} className="col-md-3 mb-3">
					<div className="card soko-round shadow-lg h-100">
					<div className="card-body">
					<img loading="lazy" className="img-fluid product-img" src={productsObj.image ? `https://mysoko.codekali.com/product/image/${productsObj.image}` : `https://mysoko.codekali.com/images/avatar.png`} 
					alt="{productsObj.name}" />
					<Link to={`/product/${productsObj.id}`}>
                       <h6 className="soko-bold">{capFirst(productsObj.name)}</h6>
					</Link>
					<p><strong> KES {productsObj.price}</strong></p>

					<div className="row">

                         <div className="col-md-6 mb-3">
					<Link to={''} className='btn btn-primary w-100 soko-round soko-mobile-font me-2'>
					<FontAwesomeIcon icon={faShoppingCart} />
					Add to Cart
					</Link>
					</div>
                         <div className="col-md-6 mb-3">
					<Link className="btn btn-warning soko-round w-100 soko-mobile-font" to={`/product/${productsObj.id}`}>
					<FontAwesomeIcon icon={faEye} />
					View</Link>
					</div>

					</div>


					</div>
					
					</div>
					</div>
					)
					}
					
				</div>
			</div>
		</div>
	</div>
      <Footer/>
	</>
	)
}

export default SearchProduct