import React from "react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";

const Shop = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const fetchData = () => {
    return (
      fetch("https://mysoko.codekali.com/api/getproducts")
        // return fetch("http://127.0.0.1:8000/api/getproducts")
        .then((response) => response.json())
        .then((data) => setProducts(data["products"]))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

  useEffect(() => {
    setIsLoading(true);
    fetchData();
    // setIsLoading(false);
  }, []);

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="py-3"></div>
        <div className="product-container">
          <div className="row">
            {!isLoading
              ? products &&
                products.length > 0 &&
                products.map((productsObj) => (
                  <div key={productsObj.id} className="col-md-3 mb-3">
                    <div className="card soko-round shadow-lg h-100">
                      <div className="card-body">
                        <Link to={`/product/${productsObj.slug}`}>
                          <img
                            loading="lazy"
                            className="img-fluid product-img w-100"
                            src={
                              productsObj.image
                                ? `https://mysoko.codekali.com/product/image/${productsObj.image}`
                                : `https://mysoko.codekali.com/images/avatar.png`
                            }
                            alt="{productsObj.name}"
                          />
                          <div className="mb-2">
                            <small>
                              <span className="badge badge-warning bg-warning soko-round">
                                {productsObj.category.name}
                              </span>
                            </small>
                          </div>
                          <h6 className="mb-2">{capFirst(productsObj.name)}</h6>
                        </Link>
                        <h6 className="soko-bold">Ksh.{productsObj.price}</h6>

                        <Link
                          className="btn btn-warning w-100 soko-round"
                          to={`/product/${productsObj.slug}`}
                        >
                          <FontAwesomeIcon icon={faCartPlus} /> Shop
                        </Link>
                      </div>
                    </div>
                  </div>
                ))
              : Array(50)
                  .fill()
                  .map((item, index) => (
                    <div className="col-md-3 mb-3" key={index}>
                      <div className="card soko-round shadow-lg">
                        <div className="card-body">
                          <div
                            style={{ height: "200px", marginBottom: ".5rem" }}
                          >
                            <Skeleton height={200} width={`100%`} />
                          </div>
                          <h6 className="card-title">
                            <Skeleton height={25} width={`80%`} />
                          </h6>
                          <p className="card-channel mb-0">
                            <Skeleton width={`60%`} />
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Shop;
