import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faComments } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import { useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import ProgressiveImage from "react-progressive-graceful-image";
import placeholder from "../images/lazyloader.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

function Product() {
  const { slug } = useParams();

  const [name, setName] = useState("");
  const [category_name, setCategory] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [price, setPrice] = useState(null);

  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = () => {
      return fetch(`https://mysoko.codekali.com/api/product/${slug}`)
        .then((response) => response.json())
        .then((data) => setProducts(data["products"]))
        .finally(() => {
          setIsLoading(false);
        });
    };

    const fetchProduct = async () => {
      await axios
        .get(`https://mysoko.codekali.com/api/product/${slug}`)
        .then(({ data }) => {
          const { name, description, image, price } = data.product;

          console.log(data);

          setName(name);
          setCategory(data.category_name);
          setDescription(description);
          setImage(image);
          setPrice(price);
        })
        .catch(({ response: { data } }) => {
          Swal.fire({
            text: data.message,
            icon: "error",
          });
        });
    };
    fetchProduct();
    fetchData();
  }, [slug]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }

 
  // End MPESA
  const productsCount = products.length;

  return (
    <>
      <Header />
      <div className="container-fluid">
        <div className="py-3"></div>
        <div className="row">
          <div className="col-md-6 mb-3">
            <div className="card soko-round shadow-sm h-100">
              <div className="card-body">
                <ProgressiveImage
                  src={
                    image
                      ? `https://mysoko.codekali.com/product/image/${image}`
                      : { placeholder }
                  }
                  placeholder={placeholder}
                >
                  {(src, loading) => (
                    <img
                      className={`image${
                        loading
                          ? " loading sokomoko-single-product w-100"
                          : " loaded sokomoko-single-product w-100"
                      }`}
                      src={src}
                      alt="home1"
                    />
                  )}
                </ProgressiveImage>
 
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="card soko-round shadow-sm h-100">
              <div className="card-body">
                <span className="badge badge-warning bg-warning mb-3 soko-round">
                  {category_name}
                </span>
                <h4 className="text-dark mb-3 soko-bold">{name}</h4>
                <h2 className="soko-bold text-warning mb-3">KES.{price}</h2>
                <p className="mb-3">
                  {description ? description : `New Product`}{" "}
                </p>
                <div className="row mt-5">
                  <div className="col-md-6 mb-3">
                    <Button
                      className="soko-round w-100"
                      variant="primary"
                      onClick={handleShow}
                    >
                      <FontAwesomeIcon className="me-1" icon={faCartPlus} />
                      Buy via MPESA
                    </Button>
                  </div>

                  <div className="col-md-6">
                    <Link className="btn btn-warning soko-round w-100" to={""}>
                      <FontAwesomeIcon className="me-1" icon={faHeart} />
                      Add to Wishlist
                    </Link>
                  </div>
                </div>
                <Modal
                  animation={false}
                  show={show}
                  onHide={handleClose}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      <h6 className="text-site soko-bold">
                        <small>Buy {name}</small>
                      </h6>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <span className="badge badge-warning bg-warning soko-round">
                      Total: Ksh. {price}
                    </span>
                    <p></p>
                    <input
                      className="form-control mb-3"
                      type="hidden"
                      name="phone"
                      value={price}
                    />
                    <input
                      className="form-control soko-round mb-3"
                      type="text"
                      name="phone"
                      placeholder="E.g 0712345678"
                    />
                    <div className="col-md-4">
                      <Button className="soko-round w-100" variant="primary">
                        Pay Now
                      </Button>
                    </div>
                    <div className="py-3"></div>
                    <small style={{ fontSize: "12px" }}>
                      * Click Pay Now and Wait for an MPESA prompt on your phone
                      then ENTER PIN to confirm...
                    </small>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="danger"
                      className="soko-round"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>

        <div className="card mb-3 shadow-sm soko-rounded">
          <div className="card-body">
            <Tabs
              defaultActiveKey="product-desc"
              id="soko-product-info-bar"
              className="mb-3"
              justify
            >
              <Tab
                eventKey="product-desc"
                title={
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faList} /> Description{" "}
                  </span>
                }
              >
                 {description ? description : `New Product`}{" "}
              </Tab>

              <Tab
                eventKey="product-specs"
                title={
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faFile} /> Specifications{" "}
                  </span>
                }
              >
                {description ? description : `New Product`}{" "}
              </Tab>

              <Tab
                eventKey="product-reviews"
                title={
                  <span>
                    {" "}
                    <FontAwesomeIcon icon={faComments} /> Reviews{" "}
                  </span>
                }
              >
                product reviews
              </Tab>
            </Tabs>
          </div>
        </div>


{productsCount > 0 ? 

         <div className="card soko-round">
          <div className="card-body">
            <div className="product-container">
              <h4 className="soko-bold text-center">Related Products</h4>
              <div className="row">
                {!isLoading
                  ? products &&
                    products.length > 0 &&
                    products.map((productsObj) => (
                      <div key={productsObj.id} className="col-md-3 mb-3">
                        <div className="card soko-round shadow-sm h-100">
                          <div className="card-body">
                            <ProgressiveImage
                              src={
                                productsObj.image
                                  ? `https://mysoko.codekali.com/product/image/${productsObj.image}`
                                  : `https://mysoko.codekali.com/images/avatar.png`
                              }
                              placeholder={placeholder}
                            >
                              {(src, loading) => (
                                <img
                                  className={`image${
                                    loading
                                      ? " loading product-img w-100"
                                      : " loaded sokomoko-single-product w-100"
                                  }`}
                                  src={src}
                                  alt="home1"
                                />
                              )}
                            </ProgressiveImage>
                            <div className="mb-2">
                              <span className="badge badge-warning bg-warning soko-round">
                                {productsObj.category.name}
                              </span>
                            </div>
                            <Link to={`/product/${productsObj.slug}`}>
                              <h6 className="mb-2">
                                {capFirst(productsObj.name)}
                              </h6>
                            </Link>
                            <h6 className="soko-bold">
                              KES. {productsObj.price}
                            </h6>

                            <Link
                              className="btn btn-outline-primary soko-round w-100"
                              to={`/product/${productsObj.slug}`}
                            >
                              <FontAwesomeIcon
                                className="me-1"
                                icon={faCartPlus}
                              />{" "}
                              Shop
                            </Link>
                          </div>
                        </div>
                      </div>
                    ))
                  : Array(50)
                      .fill()
                      .map((item, index) => (
                        <div className="col-md-3 mb-3" key={index}>
                          <div className="card soko-product-card soko-round shadow-sm">
                            <div className="card-body">
                              <div
                                style={{
                                  height: "200px",
                                  marginBottom: ".5rem",
                                }}
                              >
                                <Skeleton height={200} width={`100%`} />
                              </div>
                              <h6 className="card-title">
                                <Skeleton height={25} width={`80%`} />
                              </h6>
                              <p className="card-channel mb-0">
                                <Skeleton width={`60%`} />
                              </p>
                              <p className="card-channel">
                                <Skeleton width={`60%`} />
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
              </div>
            </div>
          </div>
        </div> 

        :   <h4 className="text-center soko-bold">No Related Products</h4>  
 
       }
      </div>
      <Footer />
    </>
  );


}

export default Product;
