import React, { useState, useEffect, useRef } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import DefaultImage from "../assets/images/noimage.png";
import EditIcon from "../assets/images/upload.png";
import UploadingAnimation from "../assets/images/soko-loader.gif";
import Select from "react-select";

export default function CreateProduct() {
  const navigate = useNavigate();

  const [cats, setCats] = useState([]);
  const [subcats, setSubCats] = useState([]);


  const catOptions = [];
  cats.forEach((mycat, index) => {
    catOptions.push({ value: mycat.id, label: mycat.name });
  });

    const subCatOptions = [];
  subcats.forEach((mysubcat, index) => {
    subCatOptions.push({ value: mysubcat.id, label: mysubcat.name });
  });

  let user = JSON.parse(localStorage.getItem("user-info"));

  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [vendor, setVendor] = useState(
    user && user.user.slug ? user.user.slug : ""
  );
  const [description, setDescription] = useState("");
  const [category_id, setCategory] = useState("");
  const [sub_category_id, setSubCategory] = useState("");
  const [image, setImage] = useState("");
  const [validationError, setValidationError] = useState({});

  const [isClearable, setIsClearable] = useState(true);
  const [isSearchable, setIsSearchable] = useState(true);


    const handleSubCatChange = (event, value) => {
    if (!event) {
      setSubCategory("");
    } else {
      setSubCategory(event.value);
    }
  };

  const handleCatChange = (event, value) => {
    if (!event) {
      setCategory("");
    } else {
      setCategory(event.value);
    }
  };

  useEffect(() => {
    fetchCats();
    fetchSubCats();
  }, []);

  const fetchCats = () => {
    return (
      fetch("https://mysoko.codekali.com/api/categories")
        // return fetch("http://127.0.0.1:8000/api/categories")
        .then((response) => response.json())
        .then((data) => setCats(data["categories"]))
    );
  };

    const fetchSubCats = () => {
    return (
      fetch("https://mysoko.codekali.com/api/subcategories")
        // return fetch("http://127.0.0.1:8000/api/categories")
        .then((response) => response.json())
        .then((data) => setSubCats(data["subcategories"]))
    );
  };

  const createProduct = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append("name", name);
    formData.append("vendor", vendor);
    formData.append("price", price);
    formData.append("description", description);
    formData.append("category_id", category_id);
    formData.append("sub_category_id", sub_category_id);
    formData.append("image", image);

    await axios
      .post(`https://mysoko.codekali.com/api/products`, formData)
      .then(({ data }) => {
        // await axios.post(`http://localhost:8000/api/products`, formData).then(({data})=>{
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        navigate("/products");
      })
      .catch(({ response }) => {
        if (response.status === 422) {
          setValidationError(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  const [avatarURL, setUpdPlaceholderURL] = useState(DefaultImage);

  const fileUploadRef = useRef();

  const handleImageUpload = (event) => {
    event.preventDefault();
    fileUploadRef.current.click();
  };

  //  const changeHandler = (event) => {
  //    setImage(event.target.files[0]);
  // };

  const uploadImageDisplay = async () => {
    try {
      setUpdPlaceholderURL(UploadingAnimation);

      const uploadedFile = fileUploadRef.current.files[0];
      const formData = new FormData();

      // alert(uploadedFile);

      if (uploadedFile === undefined) {
        setUpdPlaceholderURL(DefaultImage);
        setImage("");
        return;
      }

      setImage(uploadedFile);

      formData.append("file", uploadedFile);

      // const cachedURL = URL.createObjectURL(uploadedFile);
      // setUpdPlaceholderURL(cachedURL);

      const response = await fetch(
        "https://api.escuelajs.co/api/v1/files/upload",
        {
          method: "post",
          body: formData,
        }
      );

      if (response.status === 201) {
        const data = await response.json();
        setUpdPlaceholderURL(data?.location);
      }
    } catch (error) {
      console.error(error);
      setUpdPlaceholderURL(DefaultImage);
    }
  };

  return (
    <>
      <Header />
      <div className="container">
        <div className="py-3"></div>
        <div className="card shadow-sm soko-rounded">
          <div className="card-body">
            <h4 className="soko-bold mb-3">Post Ad</h4>
            <div className="form-wrapper">
              {Object.keys(validationError).length > 0 && (
                <div className="row">
                  <div className="col-12">
                    <div className="alert alert-danger">
                      <ul className="mb-0">
                        {Object.entries(validationError).map(([key, value]) => (
                          <li key={key}>{value}</li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
              <Form onSubmit={createProduct}>
                <Row className="mb-2">
                  <Col md={6}>
                    <Form.Group controlId="Category">
                      <Form.Label>Category</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={catOptions[0]}
                        isClearable={isClearable}
                        name="category_id"
                        isSearchable={isSearchable}
                        options={catOptions}
                        onChange={handleCatChange}
                      />
                    </Form.Group>
                  </Col>

                     <Col md={6}>
                    <Form.Group controlId="SubCategory">
                      <Form.Label>SubCategory</Form.Label>
                      <Select
                        className="basic-single"
                        classNamePrefix="select"
                        defaultValue={subCatOptions[0]}
                        isClearable={isClearable}
                        name="sub_category_id"
                        isSearchable={isSearchable}
                        options={subCatOptions}
                        onChange={handleSubCatChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={6}>
                    <Form.Group controlId="Name">
                      <Form.Label>Product Name</Form.Label>
                      <Form.Control
                        type="text"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group controlId="Price">
                      <Form.Label>Price(KES)</Form.Label>
                      <Form.Control
                        type="number"
                        value={price}
                        onChange={(event) => {
                          setPrice(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                    <Col>
                    <Form.Group controlId="Vendor">
                      <Form.Control
                        type="text"
                        hidden={true}
                        readOnly={true}
                        value={vendor}
                        onChange={(event) => {
                          setVendor(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="Description">
                      <Form.Label>Description</Form.Label>
                      <Form.Control
                        as="textarea"
                        className="soko-textzone"
                        rows={3}
                        value={description}
                        onChange={(event) => {
                          setDescription(event.target.value);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <Form.Group controlId="Image">
                      <Form.Label>Choose Image</Form.Label>
                      <Form.Control
                        accept="image/*"
                        type="file"
                        ref={fileUploadRef}
                        onChange={uploadImageDisplay}
                        hidden
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="row">
                  <div title="Choose Image"
                    className="col-md-3 mb-3 product-upload-card soko-pointer"
                    onClick={handleImageUpload}
                  >
                    <img
                      src={EditIcon}
                      alt="Edit"
                      className="object-fit-cover upload-icon"
                    />
                  </div>

                  <div className="col-md-3">
                    <img
                      src={avatarURL}
                      alt="Avatar"
                      className="rounded-full product-img-preview"
                    />
                  </div>
                </div>
                <Button
                  variant="primary"
                  className="mt-2"
                  size="lg"
                  block="block"
                  type="submit"
                >
                  Post Free Ad
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}