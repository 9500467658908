import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {Link} from 'react-router-dom';

import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Header from './layouts/Header';
import Footer from './layouts/Footer';

export default function Assets() {
 
 
  return (
   <>
      <Header/>
      <div className="container">
         <div className="py-3"></div>
                     <div className="card">
                  <div className="card-body">
                     <h4 className="card-title">App Assets</h4>
                        <li className="mb-3">
                     <Link className="text-dark" to={`/select`}>Select<FontAwesomeIcon className="float-end" icon={faAngleRight} /> </Link>
                  </li>
                  </div>
               </div>
      </div>
      <Footer/>
   </>
  )
}