import React from "react";
import ProgressiveImage from "react-progressive-graceful-image";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Carousel from "react-bootstrap/Carousel";
import "react-multi-carousel/lib/styles.css";
import Header from "./layouts/Header";
import Footer from "./layouts/Footer";
import homebanner from "../images/homebanner.jpeg";
import homebanner2 from "../images/homebanner2.jpeg";
import placeholder from "../images/lazyloader.png";
import postad from "../images/postad-blue.png";
import bannerload from "../images/bannerload.jpg";


const Home = (props) => {
  const nextIcon = <FontAwesomeIcon className="fs-3" icon={faAngleRight} />;
  const prevIcon = <FontAwesomeIcon className="fs-3" icon={faAngleLeft} />;

  const [homecats, setHomeCats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = () => {
    return (
      fetch("https://mysoko.codekali.com/api/categories")
        // return fetch("http://127.0.0.1:8000/api/categories")
        .then((response) => response.json())
        .then((data) => setHomeCats(data["categories"]))
        .finally(() => {
          setIsLoading(false);
        })
    );
  };

  // console.log(homecats);

  const [products, setProducts] = useState([]);
  const [isLoadingPrd, setIsLoadingPrd] = useState(true);
  const fetchProducts = () => {
    return (
      fetch("https://mysoko.codekali.com/api/getproducts")
        // return fetch("http://127.0.0.1:8000/api/getproducts")
        .then((response) => response.json())
        .then((data) => setProducts(data["products"]))
        .finally(() => {
          setIsLoadingPrd(false);
        })
    );
  };

  useEffect(() => {
    setIsLoading(true);
    setIsLoadingPrd(true);
    fetchProducts();
    fetchData();
    // setIsLoading(false);
  }, []);

  function capFirst(str) {
    let dots;

    if (str.length > 50) {
      dots = "...";
    } else {
      dots = "";
    }

    return (
      (str[0].toUpperCase() + str.slice(1).toLowerCase()).slice(0, 50) + dots
    );
  }

  const [isSearching, setIsSearching] = useState(false);


const searchCSS = "soko-search-results"
const searchHidden = searchCSS + " d-none"


  const [searchproducts, setSearchData] = useState([]);

  async function sokoSearch(key) {


    if(key === ''){

          setIsSearching(false);
      // alert('empty');
    }else{


          setIsSearching(true);
    }



    let result = await fetch("https://mysoko.codekali.com/api/search/" + key);

    result = await result.json();

    if(result.length > 0){

    setSearchData(result);
    }else{
          setIsSearching(false);
      // alert('empty');

    }
    // console.warn(result);


    // console.log(searchproducts);
  }

  return (
    <>
      <Header />
      <div className="soko-app-search px-2 px-md-0 px-lg-0 mb-3">
        <div className="position-relative soko-app-search-wrapper col-md-6 text-center offset-md-3">
          <div>
            <h6 className="text-white">
              Find anything in{" "}
              <span className="soko-bold">
                <FontAwesomeIcon icon={faMapMarkerAlt} /> All Kenya
              </span>
            </h6>
          </div>
     <div className="form-group">
     <input
            onChange={(e) => sokoSearch(e.target.value)}
            className="form-control shadow-none"
            type="search"
            placeholder="Search Anything..."
          />
     </div>
          <div className={isSearching ? searchCSS : searchHidden}>
            <ul className="list-group">
              {searchproducts.map((productsObj) => (
                <Link
                  to={`/product/${productsObj.slug}`}
                  key={productsObj.id}
                  className="list-group-item d-flex justify-content-between"
                >
                  <div className="">
             

                    <ProgressiveImage src={
                        productsObj.image
                          ? `https://mysoko.codekali.com/product/image/${productsObj.image}`
                          : `https://mysoko.codekali.com/images/avatar.png`
                      } placeholder={postad}>
                      {(src, loading) => (
                        <img
                          className={`image${
                            loading
                              ? " loading img-fluid search-product-img"
                              : " loaded img-fluid search-product-img"
                          }`}
                          src={src}
                      alt={productsObj.name}
                        />
                      )}
                    </ProgressiveImage>

                  </div>

                  <div className="text-end">
                    <p className="soko-bold">{capFirst(productsObj.name)}</p>

                    <p>KES {productsObj.price}</p>
                  </div>
                </Link>
              ))}
            </ul>
          </div>
        </div>
      </div>

      <div className="container-fluid">
        <div className="row mb-3">
          <div id="soko_cat_menu" className="col-md-3">
            <aside id="soko-left-menu" className="shadow-sm">
              <ul className="soko-cats-menu">
                {!isLoading
                  ? homecats &&
                    homecats.length > 0 &&
                    homecats.map((homecatsObj) => (
                      <li key={homecatsObj.id}>
                        <Link to={`/category/${homecatsObj.slug}`}>
                          <img
                            loading="lazy"
                            className="img-fluid nav-cat-img me-2"
                            src={
                              homecatsObj.image
                                ? `https://mysoko.codekali.com/category/image/${homecatsObj.image}`
                                : `https://mysoko.codekali.com/app/images/avatar.png`
                            }
                            alt="{homecatsObj.name}"
                          />
                          {homecatsObj.name}{" "}
                          <FontAwesomeIcon
                            className="float-end me-3 nav-cat-icon"
                            icon={faAngleRight}
                          />
                        </Link>
                        {homecatsObj.subcategories.length > 0 ? (
                          <ul className="shadow-sm">
                            {homecatsObj.subcategories.slice(0, 7) &&
                              homecatsObj.subcategories.slice(0, 7).length >
                                0 &&
                              homecatsObj.subcategories
                                .slice(0, 7)
                                .map((csubcatsObj) => (
                                  <li key={csubcatsObj.id}>
                                    <Link
                                      to={`/subcategory/${csubcatsObj.slug}`}
                                    >
                                      <img
                                        loading="lazy"
                                        className="img-fluid nav-cat-img me-2"
                                        src={
                                          csubcatsObj.image
                                            ? `https://mysoko.codekali.com/subcategory/image/${csubcatsObj.image}`
                                            : `https://mysoko.codekali.com/app/images/avatar.png`
                                        }
                                        alt="{csubcatsObj.name}"
                                      />{" "}
                                      {csubcatsObj.name}
                                    </Link>
                                  </li>
                                ))}
                          </ul>
                        ) : (
                          ""
                        )}
                      </li>
                    ))
                  : Array(7)
                      .fill()
                      .map((item, index) => (
                        <li key={index}>
<div className="d-flex justify-content-between">
<div className="col-md-3">
                           <Skeleton rectangle={true} height={40} width={60} />

</div>

<div className="col-md-9">

                          <Skeleton height={40} width={`100%`} />
</div>
</div>
                        </li>  
                      ))}
              </ul>
            </aside>
          </div>
          <div className="col-md-9 mb-3">
            <div className="row">
              <div className="col-md-8 mb-3">
                <Carousel nextIcon={nextIcon} prevIcon={prevIcon} fade>
                  <Carousel.Item>
                    <ProgressiveImage src={homebanner} placeholder={homebanner}>
                      {(src, loading) => (
                        <img
                          className={`image${
                            loading
                              ? " loading d-block w-100"
                              : " loaded d-block w-100"
                          }`}
                          src={src}
                          alt="home1"
                        />
                      )}
                    </ProgressiveImage>
                  </Carousel.Item>
                  <Carousel.Item>
                    <ProgressiveImage
                      src={homebanner2}
                      placeholder={homebanner2}
                    >
                      {(src, loading) => (
                        <img
                          className={`image${
                            loading
                              ? " loading d-block w-100"
                              : " loaded d-block w-100"
                          }`}
                          src={src}
                          alt="home4"
                        />
                      )}
                    </ProgressiveImage>
                  </Carousel.Item>
                </Carousel>
              </div>

              <div className="col-md-4 mobile-hide">
                <Link to={`/sell`}>
                  {/* <div className="soko-post-ad card">
                    <FontAwesomeIcon className="fs-1" icon={faPlus} />
                  </div>*/}

                  <div className="soko-ad">
                    <ProgressiveImage src={postad} placeholder={postad}>
                      {(src, loading) => (
                        <img
                          className={`image${
                            loading
                              ? " loading soko-ad-img d-block w-100"
                              : " loaded soko-ad-img d-block w-100"
                          }`}
                          src={src}
                          alt="home4"
                        />
                      )}
                    </ProgressiveImage>
                  </div>
                </Link>
              </div>
            </div>

            <div className="row g-1">
              <h4 className="mb-3 soko-bold">Trending Ads</h4>
              {!isLoadingPrd
                ? products &&
                  products.length > 0 &&
                  products.map((productsObj) => (
                    <div key={productsObj.id} className="col-lg-3 col-6 mb-3">
                          <Link to={`/product/${productsObj.slug}`}>
                      <div className="card soko-rounded shadow-sm h-100">
                        <div className="card-body">
                            <img
                              loading="lazy"
                              className="img-fluid mb-2 product-img w-100"
                              src={
                                productsObj.image
                                  ? `https://mysoko.codekali.com/product/image/${productsObj.image}`
                                  : `https://mysoko.codekali.com/app/images/avatar.png`
                              }
                              alt="{productsObj.name}"
                            />
                           
                            <h6 className="mb-2">
                              {capFirst(productsObj.name)}
                            </h6>
                          <h6 className="text-primary soko-bold">Ksh.{productsObj.price}</h6>

                         
                        </div>
                      </div>
                          </Link>
                    </div>
                  ))
                : Array(50)
                    .fill()
                    .map((item, index) => (
                      <div className="col-md-3 mb-3" key={index}>
                        <div className="card soko-round shadow-sm">
                          <div className="card-body">
                            <div>
                              <Skeleton height={200} width={`100%`} />
                            </div>
                            <h6 className="card-title">
                              <Skeleton height={25} width={`80%`} />
                            </h6>
                            <p className="card-channel mb-0">
                              <Skeleton width={`60%`} />
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}
            </div>
          </div>
        </div>
        <div className="row soko-home-cats">
          {!isLoading
            ? homecats &&
              homecats.length > 0 &&
              homecats.map((homecatsObj) => (
                <div key={homecatsObj.id} className="col-md-2 col-6 mb-3">
                  <Link
                    className="text-site"
                    to={`/category/${homecatsObj.slug}`}
                  >
                    <div className="card soko-cat-card soko-round shadow-sm h-100">
                      <div className="card-img-top">
                        <img
                          loading="lazy"
                          className="img-fluid soko-cat-img soko-round"
                          src={
                            homecatsObj.image
                              ? `https://mysoko.codekali.com/category/image/${homecatsObj.image}`
                              : `https://mysoko.codekali.com/app/images/avatar.png`
                          }
                          alt="{homecatsObj.name}"
                        />
                      </div>
                      <div className="card-img-overlay text-white d-flex justify-content-center align-items-center">
                        <h6 className="text-warning soko-bold">
                          {homecatsObj.name}
                        </h6>
                      </div>
                    </div>
                  </Link>
                </div>
              ))
            : Array(6)
                .fill()
                .map((item, index) => (
                  <div className="col-md-2 mb-3" key={index}>
                    <div className="card soko-round shadow-sm">
                      <div className="card-body">
                        <div style={{ height: "80px" }}>
                          <Skeleton height={80} width={`100%`} />
                        </div>
                        <div className="d-flex justify-content-center align-items-center">
                          <Skeleton width={`60%`} />
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
        </div>
  
      </div>
      <Footer />
    </>
  );
};

export default Home;
