import './App.css';
import './assets/admin/css/style.css';
import {Routes, Route} from 'react-router-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import Home from './components/Home';
import Shop from './components/Shop';
import Duka from './components/Duka';
import Vendor from './components/Vendor';
import Dashboard from './components/Dashboard';
import AddProduct from './components/AddProduct';
import AddCategory from './components/AddCategory';
import AddSubCategory from './components/AddSubCategory';
import Categories from './components/Categories';
import SubCategories from './components/SubCategories';
import Products from './components/Products';
import SearchProduct from './components/SearchProduct';
import Register from './components/Register';
import Login from './components/Login';
import Protected from './components/Protected';
import Users from './components/Users';
import Shops from './components/Shops';
import About from './components/About';
import Profile from './components/Profile';
import Orders from './components/Orders';
import Messages from './components/Messages';
import Bookmarks from './components/Bookmarks';
import Contact from './components/Contact';
import EditProduct from "./components/EditProduct";
import EditCategory from "./components/EditCategory";
import EditSubCategory from "./components/EditSubCategory";
import Category from "./components/Category";
import SubCategory from "./components/SubCategory";
import Assets from "./components/Assets";
import MySelect from "./components/MySelect";
import Product from "./components/Product";
import ImageUpload from "./components/ImageUpload";
import SingleHandler from "./components/SingleHandler";
import ScrollToTop from "./components/ScrollToTop";

function App() {

return(
<Router>
    <div className="App">
       <div className="page-content-wrapper">
            <Routes>
                <Route exact path="/" element={<Home/>}/>
              <Route path="/singlehandler" element={
              <Protected Cmp={SingleHandler}/>} />
                  <Route path="/upload" element={
              <Protected Cmp={ImageUpload}/>} />
                <Route path="/products" element={
              <Protected Cmp={Products}/>} />
                <Route path="/shop" element={<Shop/>}/>
                <Route path="/orders" element={<Orders/>}/>

                <Route path="/myprofile" element={
              <Protected Cmp={Profile}/>} />
                <Route exact path="/register" element={<Register/>}/>
                <Route exact path="/login" element={<Login/>}/>
                <Route path="/dashboard" element={
              <Protected Cmp={Dashboard}/>} />
              <Route path="/assets" element={
              <Protected Cmp={Assets}/>} />
              <Route path="/select" element={
              <Protected Cmp={MySelect}/>} />
                <Route path="/categories" element={<Categories/>}/>
                <Route path="/subcategories" element={<SubCategories/>}/>
                <Route exact path="/sell" element={
              <Protected Cmp={AddProduct}/>} />
                <Route path="/create-category" element={
              <Protected Cmp={AddCategory}/>} />
               <Route path="/create-subcategory" element={
              <Protected Cmp={AddSubCategory}/>} />
                <Route path="/about" element={<About/>}/>
                <Route path="/search" element={<SearchProduct/>}/>
                <Route path="/contact" element={<Contact/>}/>
                <Route path="/users" element={
              <Protected Cmp={Users}/>} />
              <Route path="/shops" element={
              <Protected Cmp={Shops}/>} />
                <Route path="/messages" element={
              <Protected Cmp={Messages}/>} />
                 <Route path="/bookmarks" element={
              <Protected Cmp={Bookmarks}/>} />
                <Route path="/product/edit/:id" element={
              <Protected Cmp={EditProduct}/>} />
                <Route path="/category/edit/:id" element={
              <Protected Cmp={EditCategory}/>} />
               <Route path="/subcategory/edit/:id" element={
              <Protected Cmp={EditSubCategory}/>} />
                <Route path="/subcategory/:slug" element={<SubCategory/>}/>
                <Route path="/category/:slug" element={<Category/>}/>
                <Route path="/product/:slug" element={<Product/>} />
                <Route path="/shop/:slug" element={<Duka/>} />
                <Route path="/duka/:id" element={<Vendor/>} />
            </Routes>
            <div className="py-3"></div>
            <ScrollToTop />
       </div>
    </div>
</Router>
);
}

export default App;
