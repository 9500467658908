import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons";
import { useParams } from 'react-router-dom'
import axios from 'axios';
import Swal from 'sweetalert2';
import Header from './layouts/Header';
import Footer from './layouts/Footer';

export default function Category() {

  const { slug } = useParams()

  const [name, setName] = useState("")

  useEffect(()=>{
     const fetchCategory = async () => {
    await axios.get(`https://mysoko.codekali.com/api/category/${slug}`).then(({data})=>{
      const { name } = data.category
      setName(name)
    }).catch(({response:{data}})=>{
      Swal.fire({
        text:data.message,
        icon:"error"
      })
    })
  } 

  const fetchData = () =>{
return fetch(`https://mysoko.codekali.com/api/category/${slug}`)
.then((response) => response.json())
.then((data) => setProducts(data['category']['products']))
 .finally(() => {
  setIsLoading(false);
 });
} 
    fetchCategory();
    fetchData();
  },[slug])



 
const [products, setProducts] = useState([]);
const [isLoading, setIsLoading] = useState(true);

   function capFirst(str) {

   let dots

   if(str.length > 50){
      dots = '...';
   }else{
      dots = '';

   }

     return (str[0].toUpperCase() + (str.slice(1)).toLowerCase()).slice(0, 50)+dots;
     
 }
  return (
    <>
      <Header/>
    <div className="container-fluid">
         <div className="py-3"></div>
     <div className="card soko-round">
            <div className="card-body">
         <h4 className="card-title soko-bold"> {isLoading ? 'Loading...' : ''+name+''} </h4>

       <div className="py-1"></div>
         <div className="product-container">
            <div className="row">
               { !isLoading ? (products&&products.length>0 && products.map(productsObj => (
               <div key={productsObj.id} className="col-md-3 mb-3">
                  <div className="card soko-round shadow h-100">
                     <div className="card-body">
                      <Link to={`/product/${productsObj.slug}`}>
                        <img loading="lazy" className="img-fluid product-img w-100" 
                        src={productsObj.image ? `https://mysoko.codekali.com/product/image/${productsObj.image}` : `https://mysoko.codekali.com/images/avatar.png`} 
                        alt="{productsObj.name}" />
                        <h6 className="mb-2">{capFirst(productsObj.name)}</h6>
                         </Link>
                        <h6 className="soko-bold mb-3">KES. {productsObj.price}</h6>
                         <Link className="btn btn-warning w-100 soko-round soko-mobile-font" to={`/product/${productsObj.slug}`}>
                        <FontAwesomeIcon className="me-1" icon={faCartPlus} />
                        Shop</Link>
                     </div>
                  </div>
               </div>
               ))) : 


                 Array(50)
               .fill()
               .map((item, index) => (
               <div className="col-md-3 mb-3" key={index}>
                  <div className="card soko-round shadow">
                     <div className="card-body">
                        <div style={{ height: "200px", marginBottom: ".5rem" }}>
                           <Skeleton height={200} width={`100%`} />
                        </div>
                           <h4 className="card-title">
                           <Skeleton height={25} width={`80%`} />
                        </h4>
                        <p className="card-channel mb-0">
                           <Skeleton width={`60%`} />
                        </p>
                        <div className="card-metrics">
                           <Skeleton width={`90%`} />
                        </div>
                     </div>
                  </div>
               </div>
               )) 
               }
            </div>
         </div>
            </div>
          </div>
    </div>
      <Footer/>
    </>
  )
}